.btn-link {
  color: var(--bs-black-rgb);
  &:hover {
    color: var(--bs-blue);
  }
}
.storage-lv1 {
  color: var(--bs-blue);
}
@media (max-width: 635px) {
  .mart-4 {
    margin-top: 1.5rem;
  }
}

.image-wrap {
  position: relative;
}

.image-wrap .delete-image {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 100;
}

.table > :not(:last-child) > * {
  padding-bottom: 1.5rem;
}

.table > :not(caption) > * > * {
  padding-right: 2.5rem;
}
